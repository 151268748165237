import { Controller } from '@hotwired/stimulus'
import { Toast } from '../lib/bootstrap'

export default class extends Controller {
  connect() {
    this.element.addEventListener('hidden.bs.toast', this.element.remove)
    this.showMessage()
  }

  showMessage() {
    const toast = new Toast(this.element)
    toast.show()
  }

  disconnect() {
    this.element.removeEventListener('hidden.bs.toast', this.element.remove)
  }
}
