import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-frame-switching"
export default class extends Controller {
  static targets = ['frame']

  connect() {
  }

  select(e) {
    const selectElement = e.currentTarget

    if (selectElement.selectedOptions.length < 1) {
      this._clearFrame()
      return
    }

    const selectedOption = selectElement.selectedOptions[0]
    const { frameSrc } = selectedOption.dataset

    if (!frameSrc || frameSrc === '') {
      this._clearFrame()
      return
    }

    this._switchFrame(frameSrc)
  }

  _clearFrame() {
    this.frameTarget.removeAttribute('src')
    this.frameTarget.innerHTML = ''
  }

  _switchFrame(src) {
    this.frameTarget.setAttribute('src', src)
  }
}
