import { Controller } from '@hotwired/stimulus'
import { Modal } from '../lib/bootstrap'

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  static targets = ['modal', 'spinnerTemplate', 'frame']

  connect() {
    this.bsModal = new Modal(this.modalTarget, { keyboard: false })
    this.modalTarget.addEventListener('hidden.bs.modal', (_e) => {
      this._renderSpinner()
    })
    this._renderSpinner()
  }

  disconnect() {
    this.bsModal.dispose()
  }

  show() {
    this.bsModal.show()
  }

  hide() {
    this.bsModal.hide()
  }

  submitEnd (e) {
    const { disableModalClose } = e.detail.formSubmission.formElement.dataset
    if (disableModalClose) return

    if (e.detail.success) {
      this.hide()
    }
  }

  _renderSpinner () {
    this.frameTarget.innerHTML = ''

    const spinner = this.spinnerTemplateTarget.content.cloneNode(true)
    this.frameTarget.append(spinner)
  }
}
